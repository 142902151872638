/** Hide global loader shown during app init */

function hideInitLoader(initLoaderSelector?: string) {
  if (!initLoaderSelector) {
    return;
  }

  const initLoader = window.document.querySelector(initLoaderSelector);

  if (!initLoader) {
    return;
  }

  initLoader.remove();
}

export default hideInitLoader;
