import type { SiteIdValues } from '../constants/siteId';

import { hostsLocalToSiteId, hostsProductionToSiteId, hostsTestingToSiteId, hostsToSiteId } from '../constants/hosts';

const allHostsToSiteId = {
  ...hostsLocalToSiteId,
  ...hostsProductionToSiteId,
  ...hostsTestingToSiteId,
  ...hostsToSiteId,
} as Partial<Record<string, SiteIdValues>>;

export function getSiteIdFromUrl(hostname = window.location.hostname, data = allHostsToSiteId) {
  const host = Object.keys(data).find(
    (item) =>
      // will math `www.sonara.ai`` hostname with `sonara.ai` item
      hostname.endsWith(item) ||
      // will match `interviewme.pl` with `app2.testing.modularfeatures.interviewme.pl` or `local.interviewme.pl` hostnames
      item.endsWith(hostname),
  );

  if (!host) {
    return;
  }

  const siteId = data[host];

  return siteId;
}
