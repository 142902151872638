import { CODENAME } from './codename';

// ADD NEW DOCUMENT TYPE CHANGES HERE

export const LOCAL_STORAGE_KEYS_TEST_FEATURES = {
  datePickerCalendar: `${CODENAME}-date-picker-calendar`,
  documentPreviewWatermark: `${CODENAME}-document-preview-watermark`,
  testFeatureExample1: `${CODENAME}-test-feature-example-1`,
  testFeatureExample2: `${CODENAME}-test-feature-example-2`,
};

export type LocalStorageKeysTestFeatures = keyof typeof LOCAL_STORAGE_KEYS_TEST_FEATURES;

export const LOCAL_STORAGE_KEYS = {
  aiInterviewLimit: `${CODENAME}-ai-interview-limit`,
  analyticsDebug: `${CODENAME}-analytics-debug`,
  devFTGraphql: `${CODENAME}-dev-ft-graphql`,
  devGraphql: `${CODENAME}-dev-graphql`,
  devInterfaceLanguage: `${CODENAME}-dev-interface-language`,
  devMenu: `${CODENAME}-dev-menu`,
  devNavigatorLanguage: `${CODENAME}-dev-navigator-language`,
  devNavigatorLanguageAll: `${CODENAME}-dev-navigator-language-all`,
  devReferrer: `${CODENAME}-dev-referrer`,
  devReferrerAll: `${CODENAME}-dev-referrer-all`,
  devShowLogs: `${CODENAME}-dev-show-logs`,
  devTraceLogs: `${CODENAME}-dev-trace-logs`,
  disableStrictMode: `${CODENAME}-disable-strict-mode`,
  errorDebug: `${CODENAME}-error-debug`,
  eventEmitterDebug: `${CODENAME}-event-emitter-debug`,
  gtmDebug: `${CODENAME}-gtm-debug`,
  interviewCheckData: `${CODENAME}-interview-check-data`,
  navDebug: `${CODENAME}-nav-debug`,
  newRelicDebug: `${CODENAME}-new-relic-debug`,
  seenFtOnBoardingWelcomeModal: `${CODENAME}-seen-ft-onboarding-welcome-modal`,
  seenFtShareSessionModal: `${CODENAME}-seen-ft-share-session-modal`,
  segmentDebug: `${CODENAME}-segment-debug`,
  selectedProductId: `${CODENAME}-selected-product-id`,

  // AI Interview >>
  aiInterviewLatestJobTitle: `${CODENAME}-ai-interview-latest-job-title`,
  aiInterviewPassedJobDescription: `${CODENAME}-ai-interview-passed-job-description`,
  // AI Interview <<

  // Expert Apply >>
  expertApplyPaymentStatus: 'paymentStatus',
  expertApplySubscriptionStatus: 'subscriptionStatus',
  // ExpertApply <<

  ...LOCAL_STORAGE_KEYS_TEST_FEATURES,
};

export type LocalStorageKeys = keyof typeof LOCAL_STORAGE_KEYS;
