/**
 * Simple service to log out console messages
 */

import { LOCAL_STORAGE_KEYS } from '../constants/localStorage';

const tempConfig = {
  get showLogs() {
    // console.log({
    //   localStorage: localStorage.getItem(LOCAL_STORAGE_KEYS.devShowLogs),
    //   processEnvStoryBook: process.env.MF_IS_STORYBOOK,
    //   processEnvTest: process.env.NODE_ENV === 'test',
    //   windowMf: window.mf?.TODO_TEMP_ENABLE_DEV_MENU,
    // });

    return (
      (process.env.NODE_ENV !== 'test' &&
        (localStorage.getItem(LOCAL_STORAGE_KEYS.devShowLogs) === 'true' ||
          process.env.MF_IS_STORYBOOK === 'true' ||
          window.mf?.TODO_TEMP_ENABLE_DEV_MENU)) ||
      false
    );
  },
};

const useTraceForLogs = localStorage.getItem(LOCAL_STORAGE_KEYS.devTraceLogs) === 'true';

const logCommand = useTraceForLogs ? 'trace' : 'log';

interface PrintToConsoleParamsObj {
  color?: string;
  icon?: string;
  message: string;
  type: 'log' | 'trace' | 'warn' | 'error';
}

const printToConsole = ({ type, message, color, icon }: PrintToConsoleParamsObj, ...args: unknown[]) => {
  // eslint-disable-next-line no-console
  console[type](
    '%c' + `${icon ?? ''} ${message}${args.length ? ':' : ''}`.trim(),
    `color: ${color ?? 'initial'}`,
    ...args,
  );
};

const log = (message: string, ...args: unknown[]) =>
  tempConfig.showLogs &&
  printToConsole({ color: 'deepskyblue', icon: '🪵' /* log, xD */, message, type: logCommand }, ...args);
const success = (message: string, ...args: unknown[]) =>
  tempConfig.showLogs && printToConsole({ color: 'limegreen', icon: '🏆', message, type: logCommand }, ...args);
const warn = (message: string, ...args: unknown[]) =>
  tempConfig.showLogs && printToConsole({ icon: '👮🏻‍♂️', message, type: 'warn' }, ...args);
const error = (message: string, ...args: unknown[]) =>
  tempConfig.showLogs && printToConsole({ icon: '⛔️', message, type: 'error' }, ...args);
const hot = (message: string, ...args: unknown[]) =>
  tempConfig.showLogs && printToConsole({ color: 'hotpink', icon: '🔥', message, type: logCommand }, ...args);
const grey = (message: string, ...args: unknown[]) => {
  if (!tempConfig.showLogs) {
    return;
  }
  if (args.length === 0) {
    printToConsole({ color: 'grey', message, type: logCommand });

    return;
  }
  /* eslint-disable no-console */
  console.groupCollapsed('%c' + message, 'color: grey');
  console[logCommand](...args);
  console.groupEnd();
  /* eslint-enable no-console */
};

const loggerService = {
  error,
  grey,
  hot,
  log,
  get showLogs() {
    return tempConfig.showLogs;
  },
  success,
  warn,
};

// export type LoggerService = typeof loggerService;

export default loggerService;
