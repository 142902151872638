const commonPolyfills = () => {
  const imports = [];

  if (!('structuredClone' in window)) {
    imports.push(import('./structuredClone'));
  }

  if (!('container' in window.document.documentElement.style)) {
    imports.push(import('container-query-polyfill'));
  }

  return imports;
};

export const loadPolyfillsFull = async () => {
  const imports = [];

  imports.push(...commonPolyfills());

  if (typeof Intl.Locale === 'undefined') {
    imports.push(import('./intlLocale'));
  }

  if (typeof Intl.PluralRules === 'undefined') {
    imports.push(import('./intlPluralRules'));
  }

  if (typeof Intl.RelativeTimeFormat === 'undefined') {
    imports.push(import('./intlRelativeTimeFormat'));
  }

  if (imports.length) {
    return Promise.all(imports);
  }

  return Promise.resolve();
};

export const loadPolyfillsLight = async () => {
  const imports = [];

  imports.push(...commonPolyfills());

  if (imports.length) {
    return Promise.all(imports);
  }

  return Promise.resolve();
};
