import { createBrowserHistory } from 'history';

import type { History } from 'history';

import { LOCAL_STORAGE_KEYS } from '@boldpl/base/constants/localStorage';
import loggerService from '@boldpl/base/services/loggerService';

// TODO XXX cleanup
export interface HistoryState {
  customBackUrl?: string;
  from?: string;
  hasResumes?: boolean;
  is404?: boolean;
  is404From?: string;
  isRecommendationClicked?: boolean;
  redirectAfterLanguageChange?: string;
  redirectToAfterAuth?: string;
  resetPasswordEmail?: string;
  shouldDisplayCustomPromoPlan?: boolean;
}

// react router's history used in our app
const history: History = createBrowserHistory();

if (localStorage?.getItem(LOCAL_STORAGE_KEYS.navDebug) === 'true') {
  history.listen(({ location }) => {
    loggerService.log(
      'Navigation',
      `${location.pathname}${location.search}${location.hash}`,
      location.state ? JSON.stringify(location.state) : '',
    );
  });
}

export default history;
