import { isEmpty } from 'lodash-es';

import type { HistoryState } from '../router/history';

// TODO XXX use window.history.replaceState
import history from '../router/history';

export function normalizePathname(pathname: string) {
  let newPathname = pathname;

  // fix multiple slashesh that breaks app (router)
  if (newPathname.includes('//')) {
    newPathname = newPathname.replace(/\/{2,}/g, '/');
  }

  return newPathname;
}

export function normalizeSearchParams(searchParams: URLSearchParams) {
  searchParams.delete('referrer');

  return searchParams.toString();
}

export function clear404OnInit() {
  const state = history.location.state as HistoryState;

  if (state?.is404) {
    const newState = {
      ...state,
    };

    delete newState.is404;

    history.replace(
      {
        hash: history.location.hash,
        pathname: history.location.pathname,
        search: history.location.search,
      },
      isEmpty(newState) ? null : newState,
    );
  }
}

function rewriteUrlOnInit() {
  const url = new URL(window.location.href);

  // our 404 pages work using history.location.state, so we remove is404 on init, to reduce WTF momemnts
  clear404OnInit();

  url.pathname = normalizePathname(url.pathname);
  url.search = normalizeSearchParams(url.searchParams);

  if (window.location.href === url.toString()) {
    return;
  }

  history.replace(`${url.pathname}${url.search}${url.hash}`);
}

export default rewriteUrlOnInit;
