import hideInitLoader from '@boldpl/common/entry/hideInitLoader';
import rewriteUrlOnInit from '@boldpl/common/entry/rewriteUrlOnInit';
import { loadPolyfillsLight } from '@boldpl/common/polyfill';

import { initParamsPassed } from '@boldpl/common/config/initParamsPassed';
import { siteIdDataLoaded } from '@boldpl/common/config/siteIdDataLoaded';
import { siteTokens } from '@boldpl/common/config/tokens/ngb';

import { getSiteIdFromUrl } from '@boldpl/common/utils/getSiteIdFromUrl';

window.mf ||= {};

(async () => {
  await loadPolyfillsLight();

  siteIdDataLoaded.name = 'Embed Features';
  siteIdDataLoaded.tokens = siteTokens;

  const siteId = getSiteIdFromUrl();

  initParamsPassed.siteId = siteId!; // ! after validateSiteId

  const renderNode = window.document.querySelector('#mf-features');

  if (!renderNode || !(renderNode instanceof HTMLElement)) {
    // TODO XXX ? throw some error
    return;
  }

  renderNode.dataset.mfEmbed = ''; // data-mf-embed selector for CSS variables

  rewriteUrlOnInit();

  const { mountApp } = await import('./mountApp');

  hideInitLoader('#mf-features-init-loader');

  mountApp(renderNode);
})();
